import React from "react";
import style from "./NavBar.module.css";
import {Link} from "react-router-dom";

const NavBar = (props) => {
  return (
    <nav className={style.topNav}>
      <div className={style.topNavContainer}>
        <div className={style.topNavLogo}>
          <Link to={"/"}>GeoDistance</Link>
        </div>
        <div className={style.topNavMenuItems}>
          <div className={style.dropdown}>
            <span>
              <Link to={"/about"}>About</Link>
            </span>
            {/* EXAMPLE DROPDOWN MENU */}
            {/*<div className={style.dropdownContent}>*/}
            {/*  <Link to={"/map"}>Attribution</Link>*/}
            {/*  <a href="#">Item 2</a>*/}
            {/*  <a href="#">Item 3</a>*/}
            {/*</div>*/}
          </div>

          <span>
            <Link to={"/map"}>Map</Link>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
