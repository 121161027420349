import styles from "./CoordinateInfoBox.module.css";
import { useEffect, useState } from "react";
import { useMapInstance } from "../../react-context/MapInstanceContext";

const COPY_LATLNG_MSG = "Ctrl+Click to copy Lat/Lng";
const DID_COPY_LATLNG_MSG = "Copied Lat/Lng";

const writeValueToClipboard = (value) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log(`Copying was successful: ${value}`);
    })
    .catch(() => {
      console.log("Error copying to clipboard");
    });
};

function CoordinateInfoBox(props) {

  const ctxMapInstance = useMapInstance();

  const [lngLat, setLngLat] = useState({
    lng: -177.70793750000075,
    lat: -63.109486212974744,
  });
  const [copied, setCopied] = useState(false);
  const [copyMsg, setCopyMsg] = useState(COPY_LATLNG_MSG);

  useEffect(() => {
    if (!ctxMapInstance.mapInstance) return;

    ctxMapInstance.mapInstance.on("mousemove", function (e) {
      setLngLat(e.lngLat.wrap());
    });

    ctxMapInstance.mapInstance.on("mousedown", function (e) {
      console.log("A mousedown event has occurred at " + e.lngLat);
      if (e.originalEvent.shiftKey) {
        console.log(`The shift key is pressed: ${e.originalEvent.shiftKey}`);
      }

      if (e.originalEvent.ctrlKey) {
        console.log(`The ctrl key is pressed: ${e.originalEvent.ctrlKey}`);
        let point = e.lngLat.wrap();
        let text2 = `LAT ${Number.parseFloat(point.lat).toFixed(
          6,
        )}, LNG ${Number.parseFloat(point.lng).toFixed(6)}`;
        writeValueToClipboard(text2);
        setCopied(true);
      }
    });
  }, [ctxMapInstance]);

  useEffect(() => {
    if (!copied) {
      return;
    }

    setCopyMsg(DID_COPY_LATLNG_MSG);
    setCopied(false);

    window.setTimeout(() => {
      setCopyMsg(COPY_LATLNG_MSG);
    }, 2000);
  }, [copied]);

  return (
    <div id="coordinate-infobox" className={styles.coordinateInfobox}>
      <div>
        <span className={styles.coordinateLabel}>Lat:</span>
        <span className={styles.coordinateNumber}>
          {Number.parseFloat(lngLat.lat).toFixed(6)}
        </span>
      </div>
      <div>
        <span className={styles.coordinateLabel}>Lng:</span>
        <span className={styles.coordinateNumber}>
          {Number.parseFloat(lngLat.lng).toFixed(6)}
        </span>
      </div>
      <div className={styles.note}>{copyMsg}</div>
    </div>
  );
}

export { CoordinateInfoBox };
