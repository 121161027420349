import styles from "./DistanceInfoBox.module.css";
import { useSelector } from "react-redux";
import UnitsChooser from "./UnitsChooser";
import {distanceMetersTo} from "../../lib/calculation-utils";
import {isNumber} from "@turf/turf";

function DistanceInfoBox(props) {
  const distance = useSelector((state) => state?.route?.distance);
  const lengthUnit = useSelector((state) => state?.route?.lengthUnit);

  const _distance = distanceMetersTo(distance, lengthUnit)
  const _d = isNumber(_distance) ? _distance : "0.00";

  return (
    <div id="distance-infobox" className={styles.distanceInfoBox}>
      <div className={styles.distanceContainer}>
        <span className={styles.distanceLabel}>Distance:</span>
        <span className={styles.distanceValue}>{_d}</span>
        <UnitsChooser />
      </div>
    </div>
  );
}

export { DistanceInfoBox };
