import React from "react";
import ReactDOM from "react-dom/client";

import { store } from "./store/store";
import { Provider } from "react-redux";

import App from "./App";

import "modern-normalize/modern-normalize.css";
import "./index.css";

// import reportWebVitals from './reportWebVitals';

// Fix for Safari Mobile Browser vh100 bug
// See: https://github.com/rodneyrehm/viewport-units-buggyfill
require("viewport-units-buggyfill").init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
