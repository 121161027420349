import { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { useSelector, useDispatch } from "react-redux";
import * as turf from "@turf/turf";
import { setDistance } from "../../store/routeSlice";
import { useMapInstance } from "../../react-context/MapInstanceContext";
import { defaultLineString } from "../Map";
import { createCustomMarkerFromSvg } from "../../lib/marker-utils";
import {
  segmentsToLinestring,
  updateGeoJsonSourceData,
} from "../../lib/geojson-utils";

import { PiFlagPennantDuotone } from "react-icons/pi";

import maplibregl from "maplibre-gl";

let startMarkerTop = null;

function RouteDrawer(props) {
  const dispatch = useDispatch();
  const segments = useSelector((state) => state?.route?.segments);

  const ctxMapInstance = useMapInstance();

  useEffect(() => {
    if (segments.length > 1) {
      const lineString = segmentsToLinestring(segments);

      if (lineString) {
        // Update linestring displayed on the map
        updateGeoJsonSourceData(
          ctxMapInstance.mapInstance,
          "geojson",
          lineString,
        );
      }

      // calculate distance
      const distance = turf.length(lineString, { units: "meters" });
      dispatch(setDistance(distance));
    } else if (segments.length === 1) {
      if (!startMarkerTop) {
        let svgMarkup = renderToStaticMarkup(
          <PiFlagPennantDuotone style={{ width: "20px", height: "20px" }} />,
        );
        let icon2 = createCustomMarkerFromSvg(svgMarkup, 20, 20);
        startMarkerTop = new maplibregl.Marker(icon2, {
          anchor: "center",
          offset: [6, -8],
        });

        startMarkerTop.setLngLat(segments[0]);
        startMarkerTop.addTo(ctxMapInstance.mapInstance);
      }

      if (ctxMapInstance && ctxMapInstance?.mapInstance) {
        updateGeoJsonSourceData(
          ctxMapInstance.mapInstance,
          "geojson",
          defaultLineString,
        );
      }

      dispatch(setDistance(0.0));
    } else if (segments.length === 0) {
      if (ctxMapInstance && ctxMapInstance?.mapInstance) {
        updateGeoJsonSourceData(
          ctxMapInstance.mapInstance,
          "geojson",
          defaultLineString,
        );

        if (startMarkerTop) {
          startMarkerTop.remove();
          startMarkerTop = null;
        }
      }

      dispatch(setDistance(0.0));
    }
  }, [ctxMapInstance, dispatch, segments]);

  // "renderless" component, used to manage polylines for the map
  return null;
}

export default RouteDrawer;
