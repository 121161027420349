import { useEffect } from "react";

function GoogleAdSenseResponsive() {
  const isProd = process.env.NODE_ENV === "production";
  console.log(`process.env.NODE_ENV = ${process.env.NODE_ENV}, isProd=${isProd}`);

  // const ads = process.env.NODE_ENV === 'production' &&
  //   <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" /> ;

  // const ads = (
  //   <script
  //     async
  //     src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
  //   />
  // );
  // console.log(ads);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  if (!isProd) {
    return null;
  }

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-0078720703797184"
        data-ad-slot="6896744208"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
}

export default GoogleAdSenseResponsive;
