import styles from "./AboutPage.module.css";

function AboutPage() {
  return (
    <div className={styles.aboutPageContainer}>
      <p>Thank you for visiting geodistance.com.</p>
      <p>
        The main motivation for developing this web site is to create a resource
        for runners, joggers, walkers, hikers, bicyclist, or anyone that is
        interested in finding out the distance of a route, trail, road, path, or other
        place.
      </p>
      <p>
        Currently the distance tracker supports the following functionality:
      </p>
      <div>
        <ul>
          <li>Create dynamic routes by clicking on the map</li>
          <li>View route distances in Miles, Yards, Feet, Kilometers, or Meters</li>
          <li>View latitude and longitude of points clicked on the earth</li>
          <li>Undo route segments/legs</li>
        </ul>
      </div>
      <p>
        Upcoming features:
      </p>
      <div>
        <ul>
          <li>Save created routes (Coming back soon!)</li>
          <li>Jump directly to a location via the address search (Coming back soon!)</li>
          <li>Email or bookmark saved routes (Coming back soon!)</li>
          <li>Find and lookup saved routes through the Find Routes function (Coming back soon!)</li>
          <li>Sort find results by route name, city, state, postal code, or distance (Coming back soon!)</li>
          <li>Export routes to a geojson or KML file (Coming back soon!)</li>
          <li>Create a training log to track personal progress and goals (Coming back soon!)</li>
          <li>Upload GPS data from a watch or device to create routes (Coming back soon!)</li>
        </ul>
      </div>
      <footer>&copy;2006-2023 geodistance.com</footer>
    </div>
  );
}

export default AboutPage;
