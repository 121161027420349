import { useEffect, useCallback } from "react";
import { useMapInstance } from "../../react-context/MapInstanceContext";
import { useDispatch } from "react-redux";
import { addSegment } from "../../store/routeSlice";
import DeleteRoute from "./DeleteRoute";
import UndoRouteSegment from "./UndoRouteSegment";

/**
 *
 * Build and manage a route.
 * Input is via user mouse click events or touch events.
 * Route segments can be straight lines or "snapped to the road".
 *
 * Output is using GeoJson LineStrings drawn on the map.
 *
 * Operations:
 *  New Route/Clear Route
 *  Click to create a new point
 *  Undo last point
 *  Reverse Route start/finish
 *  Snap to Road/Line
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RouteBuilderToolbar(props) {
  const ctxMapInstance = useMapInstance(); // Holds the mapLibre instance object
  const dispatch = useDispatch();

  // Wrap the handler in a callback.
  // This is needed because the handle is used in the useEffect hook.
  // Wrapping in a useCallback prevents unintended re-renders.
  const handleClickEvent = useCallback(
    (e) => {
      console.log("Click Event: " + e.lngLat.wrap());
      dispatch(addSegment(e.lngLat.toArray()));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!ctxMapInstance.mapInstance) {
      return;
    }

    ctxMapInstance.mapInstance.on("click", handleClickEvent);
  }, [ctxMapInstance, handleClickEvent]);

  return (
    <div className={props.className}>
      <UndoRouteSegment />
      <DeleteRoute />
    </div>
  );
}

export default RouteBuilderToolbar;
