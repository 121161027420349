import { createContext, useContext, useReducer } from "react";

const MapInstanceContext = createContext(null);
const MapInstanceDispatchContext = createContext(null);

export function MapInstanceProvider({ children }) {
  const [mapInstance, dispatch] = useReducer(
    mapInstanceReducer,
    initialMapInstance,
  );

  return (
    <MapInstanceContext.Provider value={mapInstance}>
      <MapInstanceDispatchContext.Provider value={dispatch}>
        {children}
      </MapInstanceDispatchContext.Provider>
    </MapInstanceContext.Provider>
  );
}

export function useMapInstance() {
  return useContext(MapInstanceContext);
}

export function useMapInstanceDispatch() {
  return useContext(MapInstanceDispatchContext);
}

function mapInstanceReducer(mapInstance, action) {
  switch (action.type) {
    case "setMapInstance": {
      return { mapInstance: action.mapInstance };
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialMapInstance = {
  mapInstance: null,
};
