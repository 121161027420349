import React, { useRef, useEffect, useState } from "react";
import { useMapInstanceDispatch } from "../../react-context/MapInstanceContext";
import RouteBuilderToolbar from "../RouteBuilder";
import RouteDrawer from "../RouteDrawer";
import { DistanceInfoBox } from "../DistanceInfoBox";
import { CoordinateInfoBox } from "../CoordinateInfoBox";

import maplibregl from "maplibre-gl";
import * as pmtiles from "pmtiles";

import "maplibre-gl/dist/maplibre-gl.css";

import { osm_liberty_style } from "../../gl_map_styles/osm_liberty.js";
// import {basic_preview_style} from "../../gl_map_styles/basic_preview_style";
// import {maptiler_terrain_gl_style} from "../../gl_map_styles/maptiler-terrain-gl-style";

import "./map.css";
console.log(`process.env.PUBLIC_URL = ${process.env.PUBLIC_URL}`);
console.log(osm_liberty_style);

const defaultLineString = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [],
        type: "LineString",
      },
    },
  ],
};

function Map() {
  const ctxDispatch = useMapInstanceDispatch();

  const mapRef = useRef(null);
  const map = useRef(null);

  // Initial lat,lng, and zoom when the map loads
  const [lng] = useState(139.753);
  const [lat] = useState(35.6844);
  const [zoom] = useState(1);

  useEffect(() => {
    if (map.current) return;

    let protocol = new pmtiles.Protocol();
    maplibregl.addProtocol("pmtiles", protocol.tile);

    map.current = new maplibregl.Map({
      container: mapRef.current,
      center: [lng, lat],
      zoom: zoom,
      style: osm_liberty_style,
      customAttribution: "© geodistance.com",
      // style: basic_preview_style
      // style: maptiler_terrain_gl_style
    });

    if (map.current) {
      console.log("setting map ctx");

      // Add zoom and rotation controls to the map.
      map.current.addControl(new maplibregl.NavigationControl(), 'bottom-right');
    }

    map.current.on("load", () => {
      console.log("mapLibre has completed loading");

      // Add source and layer for the route
      map.current.addSource("geojson", {
        type: "geojson",
        data: defaultLineString,
      });

      map.current.addLayer({
        id: "measure-lines",
        type: "line",
        source: "geojson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });
    });

    ctxDispatch({
      type: "setMapInstance",
      mapInstance: map.current,
    });

    // Only want to run this useEffect once.
    // eslint-disable-next-line
  }, []);

  return (
    <div className="map-container">
      <div ref={mapRef} className="map" />
      <DistanceInfoBox />
      <CoordinateInfoBox />
      <RouteBuilderToolbar className="map-route-builder" />
      <RouteDrawer />
    </div>
  );
}

export { defaultLineString };
export default Map;
