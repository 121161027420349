import Map from "../../components/Map";

function MapPage(props) {
  return (
    <div className="page-content">
      <Map />
    </div>
  );
}

export default MapPage;
