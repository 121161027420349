import { BiRun, BiWalk } from "react-icons/bi";
import { GiCycling, } from "react-icons/gi";
import { FaHiking } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import GoogleAdSenseResponsive from "../../components/GoogleAdsense";

import styles from "./HomePage.module.css";
import React from "react";

function HomePage(props) {
  const navigate = useNavigate();

  function handleGoToMap() {
    console.log("Go to map");
    navigate("/map");
  }

  return (
    <div className={styles.homePage}>
      <div className={styles.hero}>
        <div className={styles.textIconColumn}>
          <span>Walking</span>
          <BiWalk className={styles.icons} />
        </div>

        <div className={styles.textIconColumn}>
          <span>Running</span>
          <BiRun className={styles.icons} />
        </div>

        <div className={styles.textIconColumn}>
          <span>Biking</span>
          <GiCycling className={styles.icons} />
        </div>

        <div className={styles.textIconColumn}>
          <span>Hiking</span>
          <FaHiking className={`${styles.icons} ${styles.iconHiking}`} />
        </div>
      </div>
      <div className={styles.tagLine}>How far did you go?</div>
      <div className={`${styles.subText}`}>
        Find the distances of your routes, trails, bikeways, roads, paths...
      </div>
      <div className={styles.callToAction}>
        <div className={styles.callToActionLink} onClick={handleGoToMap}>
          Click here for the map
          <br />
          <br />
          Begin finding distances!
        </div>
      </div>
      <GoogleAdSenseResponsive />
    </div>
  );
}

export default HomePage;
