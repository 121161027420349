import { createSlice } from "@reduxjs/toolkit";
import { LENGTH_UNITS } from "../constants";

const initialState = {
  segments: [],
  lengthUnit: LENGTH_UNITS.MILES,
  distance: 0, // distance in meters
};

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    addSegment: (state, action) => {
      state.segments.push(action.payload);
    },
    removeLastSegment: (state) => {
      state.segments.pop();
    },
    removeAllSegments: (state) => {
      state.segments = [];
    },
    setDistance: (state, action) => {
      state.distance = action.payload;
    },
    setLengthUnits: (state, action) => {
      state.lengthUnit = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addSegment,
  removeLastSegment,
  removeAllSegments,
  setDistance,
  setLengthUnits,
} = routeSlice.actions;
export default routeSlice.reducer;
