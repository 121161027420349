import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLengthUnits } from "../../../store/routeSlice";
import { LENGTH_UNITS } from "../../../constants";

import styles from "./UnitsChooser.module.css";

function UnitsChooser(props) {
  const dispatch = useDispatch();
  const [selectedUnits, setSelectedUnits] = useState(LENGTH_UNITS.MILES);

  function handleChange(e) {
    dispatch(setLengthUnits(e.target.value));
    setSelectedUnits(e.target.value);
  }

  return (
    <>
      <span className={styles.unitsChooser}>
        <select value={selectedUnits} onChange={handleChange}>
          <option value={LENGTH_UNITS.MILES}>{LENGTH_UNITS.MILES}</option>
          <option value={LENGTH_UNITS.KILOMETERS}>km</option>
          <option value={LENGTH_UNITS.METERS}>{LENGTH_UNITS.METERS}</option>
          <option value={LENGTH_UNITS.YARDS}>{LENGTH_UNITS.YARDS}</option>
          <option value={LENGTH_UNITS.FEET}>{LENGTH_UNITS.FEET}</option>
        </select>
      </span>
    </>
  );
}

export default UnitsChooser;
