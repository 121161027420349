import * as turf from "@turf/turf";
import {LENGTH_UNITS} from "../constants";


// export declare type Units = "meters" | "millimeters" | "centimeters" | "kilometers" | "acres" | "miles" | "nauticalmiles" | "inches" | "yards" | "feet" | "radians" | "degrees" | "hectares";

function distanceMetersTo(distance, unit) {
  if(!distance) return NaN;

  const _distance = parseFloat(distance);
  if(isNaN(_distance)) return NaN;

  if(!unit) return null;

  let convertedDistance= turf.convertLength(_distance, LENGTH_UNITS.METERS, unit);
  return Number.parseFloat(convertedDistance).toFixed(2);
}

export {distanceMetersTo};