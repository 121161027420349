import { useDispatch } from "react-redux";
import { removeAllSegments } from "../../store/routeSlice";
import { MdOutlineDeleteForever } from "react-icons/md";
import styles from "../MapUI/MapButton.module.css";

function DeleteRoute(props) {
  const dispatch = useDispatch();
  const handleDeleteRoute = (e) => {
    dispatch(removeAllSegments());
  };

  return (
    <button title="Delete Route" className={`${styles.mapButton}`} onClick={handleDeleteRoute}>
      <MdOutlineDeleteForever />
    </button>
  );
}

export default DeleteRoute;
