
function createCustomMarker() {
  let customIcon = document.createElement('div');
  // customIcon.classList.add("custom-marker-style");
  customIcon.style.width = '20px';
  customIcon.style.height = '30px';
  customIcon.style.backgroundSize = "contain";
  customIcon.style.backgroundRepeat = "no-repeat";
  customIcon.style.backgroundImage = "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PCEtLSEgRm9udCBBd2Vzb21lIEZyZWUgNi40LjAgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UgKENvbW1lcmNpYWwgTGljZW5zZSkgQ29weXJpZ2h0IDIwMjMgRm9udGljb25zLCBJbmMuIC0tPjxwYXRoIGQ9Ik0zMiAwQzQ5LjcgMCA2NCAxNC4zIDY0IDMyVjQ4bDY5LTE3LjJjMzguMS05LjUgNzguMy01LjEgMTEzLjUgMTIuNWM0Ni4zIDIzLjIgMTAwLjggMjMuMiAxNDcuMSAwbDkuNi00LjhDNDIzLjggMjguMSA0NDggNDMuMSA0NDggNjYuMVYzNDUuOGMwIDEzLjMtOC4zIDI1LjMtMjAuOCAzMGwtMzQuNyAxM2MtNDYuMiAxNy4zLTk3LjYgMTQuNi0xNDEuNy03LjRjLTM3LjktMTktODEuMy0yMy43LTEyMi41LTEzLjRMNjQgMzg0djk2YzAgMTcuNy0xNC4zIDMyLTMyIDMycy0zMi0xNC4zLTMyLTMyVjQwMCAzMzQgNjQgMzJDMCAxNC4zIDE0LjMgMCAzMiAwek02NCAxODcuMWw2NC0xMy45djY1LjVMNjQgMjUyLjZWMzE4bDQ4LjgtMTIuMmM1LjEtMS4zIDEwLjEtMi40IDE1LjItMy4zVjIzOC43bDM4LjktOC40YzguMy0xLjggMTYuNy0yLjUgMjUuMS0yLjFsMC02NGMxMy42IC40IDI3LjIgMi42IDQwLjQgNi40bDIzLjYgNi45djY2LjdsLTQxLjctMTIuM2MtNy4zLTIuMS0xNC44LTMuNC0yMi4zLTMuOHY3MS40YzIxLjggMS45IDQzLjMgNi43IDY0IDE0LjRWMjQ0LjJsMjIuNyA2LjdjMTMuNSA0IDI3LjMgNi40IDQxLjMgNy40VjE5NGMtNy44LS44LTE1LjYtMi4zLTIzLjItNC41bC00MC44LTEydi02MmMtMTMtMy44LTI1LjgtOC44LTM4LjItMTVjLTguMi00LjEtMTYuOS03LTI1LjgtOC44djcyLjRjLTEzLS40LTI2IC44LTM4LjcgMy42TDEyOCAxNzMuMlY5OEw2NCAxMTR2NzMuMXpNMzIwIDMzNS43YzE2LjggMS41IDMzLjktLjcgNTAtNi44bDE0LTUuMlYyNTEuOWwtNy45IDEuOGMtMTguNCA0LjMtMzcuMyA1LjctNTYuMSA0LjV2NzcuNHptNjQtMTQ5LjRWMTE1LjRjLTIwLjkgNi4xLTQyLjQgOS4xLTY0IDkuMVYxOTRjMTMuOSAxLjQgMjggLjUgNDEuNy0yLjZsMjIuMy01LjJ6Ii8+PC9zdmc+";
  customIcon.style.cursor = "pointer";
  customIcon.style.color = "green";

  return customIcon;
}

function createCustomMarkerFromSvg(svgMarkup, width, height) {

  const base64SvGMarkup = btoa(svgMarkup);

  let customIcon = document.createElement('div');
  customIcon.style.width = `${width}px`;
  customIcon.style.height = `${height}px`;
  customIcon.style.backgroundSize = "contain";
  customIcon.style.backgroundRepeat = "no-repeat";
  customIcon.style.cursor = "pointer";
  customIcon.style.backgroundImage = `url('data:image/svg+xml;base64,${base64SvGMarkup}`;

  return customIcon;
}


export {createCustomMarker, createCustomMarkerFromSvg};