import * as turf from "@turf/turf";

/**
 *
 * @param segements
 */
function segmentsToLinestring(segments) {
  let coordinates = [];
  let lineString;

  for (let i = 0; i < segments.length; i++) {
    coordinates.push(segments[i]);
  }

  lineString = turf.lineString(coordinates);

  return lineString;
}

function updateGeoJsonSourceData(mapInstance, sourceId, geoJsonData) {
  // Make sure current source is valid
  if (mapInstance && mapInstance.getSource(sourceId)) {
    mapInstance.getSource(sourceId).setData(geoJsonData);
  }
}

export { segmentsToLinestring, updateGeoJsonSourceData };
