import { useDispatch } from "react-redux";
import { removeLastSegment } from "../../store/routeSlice";
import {MdUndo} from "react-icons/md"
import styles from "../MapUI/MapButton.module.css";

function UndoRouteSegment(props) {
  const dispatch = useDispatch();
  const handleUndoRouteSegment = (e) => {
    dispatch(removeLastSegment());
  };

  return (<button title="Undo Last Segment"  className={`${styles.mapButton}`} onClick={handleUndoRouteSegment}><MdUndo/></button>);
}

export default UndoRouteSegment;
