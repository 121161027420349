import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MapPage from "./pages/MapPage";
import AboutPage from "./pages/AboutPage";
import NavBar from "./components/NavBar";

import { MapInstanceProvider } from "./react-context/MapInstanceContext";

import "./css-variables.css";
import "./App.css";

function App() {
  return (
    <MapInstanceProvider>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </MapInstanceProvider>
  );
}

export default App;
